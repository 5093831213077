import { GeneralCMSResult } from '../../types/cms/generalTypes';
import { SENIOR_HOUSING_TAG, STUDENT_HOUSING_TAG } from '../../utils/constants';
import { Checkbox } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import { FiltersRow, FiltersRowCheckboxes, FiltersRowHeader } from './styles';
import { FilterState } from './types';

interface OtherFiltersProps {
  generalTexts: GeneralCMSResult;
  internalState: FilterState;
  onChange: (value: Partial<FilterState>) => void;
  singlePropertyGroup?: boolean;
  waitingLists?: boolean;
}

const OtherFilters = ({ generalTexts, internalState, onChange, singlePropertyGroup = false, waitingLists = false }: OtherFiltersProps) => {
  return (
    <FiltersRow>
      <FiltersRowHeader>
        <Heading tag="h3">{generalTexts.Misc.Other}</Heading>
      </FiltersRowHeader>
      <FiltersRowCheckboxes>
        {!singlePropertyGroup && (
          <>
            <Checkbox
              checked={!!internalState.tags && (JSON.parse(internalState.tags) as string[]).includes(SENIOR_HOUSING_TAG)}
              onChange={() => {
                const tags: string[] = internalState.tags ? JSON.parse(internalState.tags) : [];
                const isChecked = tags.includes(SENIOR_HOUSING_TAG);
                onChange({
                  tags: JSON.stringify(isChecked ? tags.filter((tag) => tag !== SENIOR_HOUSING_TAG) : [...tags, SENIOR_HOUSING_TAG]),
                });
              }}
            >
              {generalTexts.PropertyDetails.SeniorHousing}
            </Checkbox>
            <Checkbox
              checked={!!internalState.tags && (JSON.parse(internalState.tags) as string[]).includes(STUDENT_HOUSING_TAG)}
              onChange={() => {
                const tags: string[] = internalState.tags ? JSON.parse(internalState.tags) : [];
                const isChecked = tags.includes(STUDENT_HOUSING_TAG);
                onChange({
                  tags: JSON.stringify(isChecked ? tags.filter((tag) => tag !== STUDENT_HOUSING_TAG) : [...tags, STUDENT_HOUSING_TAG]),
                });
              }}
            >
              {generalTexts.PropertyDetails.StudentHousing}
            </Checkbox>
            <Checkbox
              checked={internalState.upcomingProject === 'true'}
              onChange={() => {
                onChange({ upcomingProject: internalState.upcomingProject === 'true' ? '' : 'true' });
              }}
            >
              {generalTexts.Misc.Upcoming}
            </Checkbox>
          </>
        )}
        <Checkbox
          checked={internalState.petsAllowed === 'true'}
          onChange={() => {
            onChange({ petsAllowed: internalState.petsAllowed === 'true' ? '' : 'true' });
          }}
        >
          {generalTexts.PropertyDetails.PetsAllowed}
        </Checkbox>
      </FiltersRowCheckboxes>
    </FiltersRow>
  );
};

export default OtherFilters;
